const initialState = {
  user_data: {
    phone: null,
    fullname: null,
    email: null,
    code: null,
    notify_by_sms: null,
  }
};

export function userReducer(state = initialState, action) {
  switch (action.type) {
    case "setUserInfo":
        return {
          ...state,
          user_data: action.payload
        }
    default:
      return state;
  }
}