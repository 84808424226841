import { store } from '../store/configureStore'

export function setUserInfo(arg) {
  return (dispatch) => {
    const user_data = store.getState().user.user_data
    //var user_data_NEW = user_data

    //Object.keys(arg).forEach(key=>{
    //  user_data_NEW[key]=arg[key]
    //})
    //user_data_NEW = Object.assign(user_data_NEW, arg);

    dispatch({
      type:    "setUserInfo",
      payload: Object.assign({}, user_data, arg)
    })
  }
}