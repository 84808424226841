import axios from 'axios';
import { encode } from "base-64";


global.UserID = Math.floor(Math.random() * (10 - 1) + 1)
var href = window.location.href.split('?');
global.serverURL_Main = href[0] + '/api';
global.URL_Main = window.location.href;
//if(window.location.hostname=="localhost"){
//  global.serverURL_Main = "http://localhost:8080/server/";
//  global.URL_Main = "http://localhost:8080";
//  //global.serverURL_Main = "https://quicksearch.futuris.shop/server/";
//  //global.URL_Main = "https://quicksearch.futuris.shop";
//}else{  
//  //global.serverURL_Main="/server/"; 
//  //global.URL_Main=document.location.protocol+"//"+document.location.host+"/";
//  global.serverURL_Main = "https://quicksearch.futuris.shop/server/";
//  global.URL_Main = "https://quicksearch.futuris.shop";
//}
//global.serverURL = global.serverURL_Main + "task.php?";
//global.serverURL = global.serverURL_Main + "index.php?";
global.serverURL = global.serverURL_Main + "?" + href[1];

global.API = {
  SEND_MAIL: "https://3.dsf.z8.ru/API/fileManager/sendMail.php",
  DOMAIN_CHECK: "https://3.dsf.z8.ru/API/AllowedDomains/checkDomain.php"
}

global.Auth = {
  LOGIN: null,
  PASSWORD: null,
  ID: null
}

global.maskPhone = (elem, mask) => maskPhone(elem, mask)
global.urlParams = () => urlParams()
global.GUID = () => GUID()
global.makeNormalDate = (Date, format) => makeNormalDate(Date, format);
global.API_task = (GET_ARGS_ARRAY) => API_task(GET_ARGS_ARRAY);
global.API_files = (operation, files, data) => API_files(operation, files, data);
global.OTHER_API_task = (URL, GET_ARGS_ARRAY) => OTHER_API_task(URL, GET_ARGS_ARRAY);
global.makeNormalDate = (Date, format) => makeNormalDate(Date, format);
global.checkReqvireds = (arrayReqv, ignorArray) => checkReqvireds(arrayReqv, ignorArray);
global.FIO = (fio, count) => FIO(fio, count);
global.okonchanie = (count, t1, t2, t3) => okonchanie(count, t1, t2, t3);
global.phoneFormatter = (phone) => phoneFormatter(phone);
global.random = () => random();
global.cookie = {
  set: (key, val) => setCookie(key, val),
  get: (key) => getCookie(key),
}
global.isThetChatMuted = (idChat) => isThetChatMuted(idChat)
global.originalWidgetUrl = () => originalWidgetUrl();

function originalWidgetUrl() {
  return document.getElementById('root').dataset['ycpage']
}

function isThetChatMuted(idChat) {
  let MutedChats = global.cookie.get("MutedChats")
  if (MutedChats == null)
    MutedChats = []
  else
    MutedChats = JSON.parse(MutedChats)

  let index = MutedChats.findIndex(chat => chat == idChat)
  return index >= 0 ? true : false
}

function random() {
  var length = 8,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

function GUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

function urlParams() {
  var params = window
    .location
    .search
    .replace('?', '')
    .split('&')
    .reduce(
      function (p, e) {
        var a = e.split('=');
        p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
        return p;
      },
      {}
    )
  return params
}

function phoneFormatter(phone) {
  if (phone == null) return null
  if (phone.length < 11) return "+" + phone
  let phArr = phone.split('')
  return `+${phArr[0]} (${phArr[1]}${phArr[2]}${phArr[3]}) ${phArr[4]}${phArr[5]}${phArr[6]}-${phArr[7]}${phArr[8]}-${phArr[9]}${phArr[10]}`
}


function okonchanie(count, t1, t2, t3) {
  if (count % 10 > 1 && count % 10 < 5 && !(count >= 10 && count <= 15)) {
    return t2;
  } else if ((count % 10 >= 5 && count % 10 <= 9) || count % 10 == 0 || (count >= 10 && count <= 15)) {
    return t3;
  } else {
    return t1;
  }
}


function makeNormalDate(Date_, format) {
  try {
    var y = Date_.getFullYear();
  } catch {
    var tmpD = Date_;
    Date_ = Date_.replace(/ /g, "T")
    Date_ = new Date(Date_);

    if (Date_.getFullYear() <= 0)
      Date_ = new Date("1998-11-26T" + tmpD);

    var y = Date_.getFullYear();
  }

  var m = Date_.getMonth() + 1; if (m < 10) m = "0" + m;
  var d = Date_.getDate(); if (d < 10) d = "0" + d;

  var H = Date_.getHours(); if (H < 10) H = "0" + H;
  var M = Date_.getMinutes(); if (M < 10) M = "0" + M;
  var S = Date_.getSeconds(); if (S < 10) S = "0" + S;
  var I = Date_.getMilliseconds();

  format = format.replace("yyyy", y);
  format = format.replace("dd", d);
  format = format.replace("mm", m);

  format = format.replace("H", H);
  format = format.replace("M", M);
  format = format.replace("S", S);
  format = format.replace("I", I);

  return format;
}


function checkReqvireds(arrayReqv, ignorArray = []) {
  var isOk = true;
  try {
    Object.keys(arrayReqv).forEach(key => {
      try {
        var itIgnor = false;
        for (var i = 0; i < ignorArray.length; i++) {
          if (ignorArray[i] == key) {
            itIgnor = true;
            break;
          }
        }

        if (!itIgnor && ((arrayReqv[key].value == null || arrayReqv[key].value.length == 0 || arrayReqv[key].value === -1 || arrayReqv[key].value == -100) && key != 'file')) {
          arrayReqv[key].classList.add("border-danger");

          isOk = false;
        } else {
          arrayReqv[key].classList.remove("border-danger");
        }
      } catch { }
    });
  } catch { }
  return isOk;
}

function OTHER_API_task(URL, GET_ARGS_ARRAY) {
  return API_task(GET_ARGS_ARRAY, URL + "?")
}

function API_task(GET_ARGS_ARRAY, URL) {
  var API_URL = URL == null ? global.serverURL : URL

  console.log("")
  console.log(API_URL)
  console.log(GET_ARGS_ARRAY)

  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: API_URL,
      data: GET_ARGS_ARRAY,
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      console.log("");
      console.log("result " + URL)
      console.log(GET_ARGS_ARRAY)
      console.log(response.data);

      if (response.data.result.success == false) {
        console.log("");
        console.error("Ошибка " + URL)
        console.log(GET_ARGS_ARRAY)
        console.error(response.data)
        reject()
      } else {
        resolve(response.data.result.data)
      }
    }).catch(error => {
      reject(error)
    })
  })
}

function maskPhone(elem, masked = '+7 (___) ___-__-__') {
  function mask(event) {
    const keyCode = event.keyCode;
    const template = masked,
      def = template.replace(/\D/g, ""),
      val = this.value.replace(/\D/g, "");
    console.log(template);
    let i = 0,
      newValue = template.replace(/[_\d]/g, function (a) {
        return i < val.length ? val.charAt(i++) || def.charAt(i) : a;
      });
    i = newValue.indexOf("_");
    if (i !== -1) {
      newValue = newValue.slice(0, i);
    }
    let reg = template.substr(0, this.value.length).replace(/_+/g,
      function (a) {
        return "\\d{1," + a.length + "}";
      }).replace(/[+()]/g, "\\$&");
    reg = new RegExp("^" + reg + "$");
    if (!reg.test(this.value) || this.value.length < 5 || keyCode > 47 && keyCode < 58) {
      this.value = newValue;
    }
    if (event.type === "blur" && this.value.length < 5) {
      this.value = "";
    }
  }

  elem.addEventListener("input", mask);
  elem.addEventListener("focus", mask);
  elem.addEventListener("blur", mask);
}

function API_files(operation, files, GET_ARGS_ARRAY = {}) {
  console.log("");
  console.log(operation)
  console.log(files)

  return new Promise((resolve, reject) => {
    var API_URL = global.serverURL
    debugger
    var formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('file' + i, files[i]);
    }

    var ARGS = "";

    Object.keys(GET_ARGS_ARRAY).forEach(function (key) {
      ARGS += "&";
      var itJSON = false;
      try {
        if (JSON.stringify(GET_ARGS_ARRAY[key])[0] == "{") {
          itJSON = true;
        }
      } catch { }
      if (itJSON)
        GET_ARGS_ARRAY[key] = JSON.stringify(GET_ARGS_ARRAY[key]);
      ARGS = ARGS + key + "=" + GET_ARGS_ARRAY[key]
    });

    console.log("")
    console.log(API_URL + ARGS)
    console.log(GET_ARGS_ARRAY)

    var Authorization = global.Auth.LOGIN != null ?
      'Basic ' + encode(global.Auth.LOGIN + ":" + global.Auth.PASSWORD)
      : null

    var headers = {}
    if (Authorization == null)
      headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    else
      headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization
      }

    axios({
      method: 'post',
      url: API_URL + "operation=" + operation + ARGS,
      headers: headers,
      data: formData
    }).then(response => {
      console.log("");
      console.log("result " + operation)
      console.log(response.data);

      if (response.data.error != undefined) {
        console.log("");
        console.error("Ошибка " + operation);
        console.error(response.data.error);
        reject()
      } else {
        resolve(response.data.result)
      }
    }).catch(error => {
      debugger
      reject(error)
    })
  })
}

function setCookie(name, value, days = 365) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function FIO(fio, count = 1) {
  let result = fio
  try {
    let array = fio.split(' ');
    switch (array.length) {
      case 1: result = array[0]; break
      case 2:
        if (count == 0)
          result = array[0]
        if (count == 1)
          result = array[0] + ' ' + array[1][0] + '. '
        if (count == 2)
          result = array[0] + ' ' + array[1]
        break
      default:
      case 3:
        if (count == 0)
          result = array[0]
        if (count == 1)
          result = array[0] + ' ' + array[1][0] + '. ' + array[2][0] + '.'
        if (count == 2)
          result = array[0] + ' ' + array[1] + ' ' + array[2][0] + '.'
        break
    }
  } catch { }
  return result
}
