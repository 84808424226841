const initialState = {
  windows: [],
};

export function popupReducer(state = initialState, action) {
  let arr=[]

  switch (action.type) {
    case "OPEN_POP_UP":
      arr = state.windows.slice()
      arr.push(action.payload)
      return {
        ...state,
        windows: arr
      };
    case "CLOSE_POP_UP":
      arr = state.windows.slice()
      arr.pop()
      return {
        ...state,
        windows: arr
      };
    default:
      return state;
  }
}