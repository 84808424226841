import {Pages} from '../reducers/page'

export function openPage(arg) {
  return (dispatch) => {
    if(Pages[arg]==null){
      console.error("Страница не найдена")
      return
    }

    dispatch({
      type: "openPage",
      payload: Pages[arg]
    })
  }
}

export function selectDate(arg) {
  console.log('select date');
  return (dispatch) => {
    dispatch({
      type: "selectDate",
      payload: arg
    })
  }
}

export function selectVariants(arg) {
  return (dispatch) => {
    dispatch({
      type: "selectVariants",
      payload: arg
    })
  }
}

export function selectCompanies(arg) {
  return (dispatch) => {
    dispatch({
      type: "selectCompanies",
      payload: arg
    })
  }
}

export function selectTime(arg) {
  return (dispatch) => {
    dispatch({
      type: "selectTime",
      payload: arg
    })
  }
}

export function selectServices(arg) {
  return (dispatch) => {
    dispatch({
      type: "selectServices",
      payload: arg
    })
  }
}

export function selectOrderType(arg) {
  return (dispatch) => {
    dispatch({
      type: "selectOrderType",
      payload: arg,
    })
  }
}

export function selectMaster(arg) {
  return (dispatch) => {
    dispatch({
      type: "selectMaster",
      payload: arg
    })
  }
}

export function selectService(arg) {
  console.log('select service');
  return (dispatch) => {
    dispatch({
        type: "selectService",
        payload: arg,
    })
  }
}

export function selectDateTime(arg) {
  return (dispatch) => {
    dispatch({
      type: "selectDateTime",
      payload: {
        dateTime: arg.dateTime,
        company: arg.company
      },
    })
  }
}

export function setCompanies(arg) {
  return (dispatch) => {
    dispatch({
      type: 'setCompanies',
      payload: arg
    });
  }
}