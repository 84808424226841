import React from 'react';
import { connect } from 'react-redux';
import { closePopUp } from '../../actions/PopUpActions';
import styled from 'styled-components';



class PopUp extends React.Component {
    constructor(props) {
        super()
        this.state = {
           visible:false
        }
        this.key = global.random()
    }

    componentDidMount() {
       setTimeout(()=>{
           this.setState({visible:true})
           
       },1)
    }

    render (){
        return (
            <Pop key={this.key} visible={this.state.visible}>
                <Close onClick={()=>this.props.closePopUp()}>х</Close>
               {this.props.children}
            </Pop>
        )
    }
}

const Pop = styled.div`
    position: fixed;
    left: 50%;
    transition: .6s cubic-bezier(.58,1.47,.37,.94);
    top: ${props=>props.visible?"50%":"70%"};
    opacity: ${props=>props.visible?"1":"0"};
    height: max-content;
    background: #fff;
    width: max-content;
    max-width:90vw;
    max-height:90vh;
    overflow:auto;
    border-radius: 20px;
    z-index: 9999;
    box-shadow: 5px 20px 50px rgba(0,0,0,0.1);
    transform: translate(-50%,-50%);
    > .Content { 
        margin:20px;
     }; 
`

const Close = styled.div`
    position: absolute;
    right: 20px;
    top: 18px;
    font-weight: 100;
    cursor: pointer;
    opacity: 0.3;
    transition: .3s;
    height: 20px;
    width: 20px;
    text-align: center;
    line-height: 19px;
    border-radius: 5px;
    box-shadow: 5px 2px 10px rgba(0,0,0,0);
    &:hover{
        opacity:1;
        background: rgb(250, 250, 250);
        box-shadow: 5px 2px 10px rgba(0,0,0,0.03);
    }
    &:active{
        font-size:9px
    }

`

const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp: () => dispatch(closePopUp()), 
    };
  };

const mapStateToProps = (store) => {
    
    return {
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(PopUp);