import OrderType from "../View/pages/OrderType";
import SelectData from "../View/pages/SelectData";
import SelectDate from "../View/pages/SelectDate";
import SelectDateTime from "../View/pages/SelectDateTime";
import SelectMaster from "../View/pages/SelectMaster";
import SelectService from "../View/pages/SelectService";
import SMScode from "../View/pages/SMScode";
import SuccessPage from "../View/pages/SuccessPage";
import UserInfo from "../View/pages/UserInfo";

export  const Pages={
  SelectData:{
    title: "Стандартная запись yClients",
    view: <SelectData/>
  },
  SelectDateTime:{
    title: "Выбор времени",
    view: <SelectDateTime/>
  },
  UserInfo:{
    title: "Данные клиента",
    view: <UserInfo/>
  },
  SuccessPage:{
    title: "Запись создана",
    view: <SuccessPage/>
  }
}

/*
 *export const Companies = [
 *  {
 *    id:177644,
 *    title:"Пражская"
 *  },
 *  {
 *    id:344272,
 *    title:"Домодедовская"
 *  },
 *  {
 *    id:389103,
 *    title:"Кузьминки"
 *  },
 *  {
 *    id:651855,
 *    title:"Коломенская"
 *  },
 *  {
 *    id:789145,
 *    title:"Юго-Западная"
 *  }
 *]
 */

const initialState = {
  user_input: {
    selected_services: [],
    selected_date: null,
    selected_dateTime: null,
    selected_variants: [],
    selected_time: {
      start: 10,
      end: 22
    },
    selected_companies: [],
  },
  page_data: {
    current_page: Pages.SelectData,
  },
  companies: []
}

export function pageReducer(state = initialState, action) {
  let mergeActionPayload = (part, param) => {
    return {
      ...state,
      [part]: { ...state[part], [param]: action.payload }
    };
  };
  let mergeUserInputPayload = (param) => {
    return mergeActionPayload('user_input', param);
  };

  switch (action.type) {
    case "openPage":
      return mergeActionPayload('page_data', 'current_page');
      /*
      return {
        ...state,
        page_data: {
          ...state.page_data,
          current_page: action.payload
        }
      }
      */
    case "selectDate":
      return mergeUserInputPayload('selected_date');
      /*
       *return {
       *  ...state,
       *  user_input: {
       *    ...state.user_input,
       *    selected_date: action.payload
       *  }
       *}
       */
    case "selectCompanies":
      return mergeUserInputPayload('selected_companies');
      /*
       *return {
       *  ...state,
       *  user_input: {
       *    ...state.user_input,
       *    selected_companies: action.payload
       *  }
       *}
       */
    case "selectTime":
      return mergeUserInputPayload('selected_time');
      /*
       *return {
       *  ...state,
       *  user_input: {
       *    ...state.user_input,
       *    selected_time: action.payload
       *  }
       *}
       */
    case "selectServices":
      return mergeUserInputPayload('selected_services');
      /*
       *return {
       *  ...state,
       *  user_input: {
       *    ...state.user_input,
       *    selected_services: action.payload
       *  }
       *}
       */
    case "selectVariants":
      return mergeUserInputPayload('selected_variants');
      /*
       *return {
       *  ...state,
       *  user_input: {
       *    ...state.user_input,
       *    selected_variants: action.payload
       *  }
       *}
       */
    case "selectOrderType":
      return mergeUserInputPayload('order_type');
      /*
       *return {
       *  ...state,
       *  user_input:{
       *    ...state.user_input,
       *    order_type: action.payload
       *  }
       *}
       */
    case "selectMaster":
      return mergeUserInputPayload('master');
      /*
       *return {
       *  ...state,
       *  user_input: {
       *    ...state.user_input,
       *    master: action.payload
       *  }
       *}
       */
    case "selectService":
      return mergeUserInputPayload('service');
      /*
       *return {
       *  ...state,
       *  user_input: {
       *    ...state.user_input,
       *    service: action.payload
       *  }
       *}
       */
    case "selectDateTime":
      return {
        ...state,
        user_input: {
          ...state.user_input,
          selected_dateTime: action.payload.dateTime,
          selected_company: action.payload.company
        }
      }
    case "setCompanies":
      return {
        ...state,
        user_input: {
          ...state.user_input,
          selected_companies: action.payload
        },
        companies: action.payload
      }
    default:
      return state;
  }
}