import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { } from '../../../actions/UserAct';
import { openPage,selectOrderType,selectService, selectMaster} from '../../../actions/PageActions';
import PageView from '../../PageView';
import CollapseListElement from '../../components/CollapseListElement';
import Loading from '../../components/Loading';
import { BtnBack } from '../../components/BackBtn';

class ServiceView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ServicesPerCategories: []
    }

    this.getServices()
  }

  getServices(){
    const {user_input} = this.props
    global.API_task({
      operation: "getServices",
      master_id: user_input.master != null ? user_input.master.id : null
    }).then( res => {
      let categories = []
      res.forEach( comp => {
        if (comp.data?.category != null) {
          comp.data.category.forEach( category => {
            let c_index = categories.findIndex( c => c.id == category.id)
            if(c_index != -1){
              if(categories[c_index].companies == null)
                categories[c_index].companies = [comp]
              else
                categories[c_index].companies.push(comp)
            } else {
              categories.push({
                ...category,
                companies: [comp]
              })
            }
          })
        }
      })

      let services = []
      res.forEach(comp=>{
        if (comp.data?.services != null) {
          comp.data.services.forEach( service => {
            let c_index = services.findIndex( c => c.id == service.id )
            if ( c_index != -1 ) {
              if (services[c_index].companies == null)
                services[c_index].companies = [comp]
              else
                services[c_index].companies.push(comp)
            } else {
              services.push({
                ...service,
                companies:[comp]
              })
            }
          })
        }
      })

      categories.forEach(category => {
        category.services = []
        let nexStepServices = []

        services.forEach(service => {
          if (service.category_id == category.id)
            category.services.push(service)
          else
            nexStepServices.push(service)
        })
        services = nexStepServices
      })

      this.setState({ ServicesPerCategories: categories })
    }).catch(er=>{
    })
  }

  render() {
    const { ServicesPerCategories } = this.state
    const { user_input } = this.props
/*
    if(ServicesPerCategories.length==0){
      return <Loading/>
    }
*/
    if (user_input.service == null)
      return(
        <ServiceBlock>
          <EmptyService.View/>
        </ServiceBlock>
      )
    else return(
      <ServiceBlock>
        <EmptyService.View/>
      </ServiceBlock>
    )
  }
}

const ServiceBlock = styled.div`
z-index:1;
`

const EmptyService = {
  Body:styled.div`
    border-radius:20px;
    border:1px solid #D8D8D8;
    height:10vw;
    min-height:100px;
    background: #FAFAFA;
    width:100%;
    position:relative;
    & p{
      position:absolute;
      top:50%;
      width:100%;
      text-align:center;
      transform:translateY(-50%);
      font-weight:300;
      font-size:20px;
    }
    & img{
      height:24px;
      width:24px;
      margin-left:-10px;
      margin-right:10px;
    }
  `,
  View: (props)=>{
    return(
      <EmptyService.Body>
        <p><img src="./img/Add.svg"/>Выберите услугу для записи</p>
      </EmptyService.Body>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openPage: (arg) => dispatch(openPage(arg)), 
    selectOrderType: (arg) => dispatch(selectOrderType(arg)), 
    selectMaster: (arg) => dispatch(selectMaster(arg)), 
    selectService: (arg) => dispatch(selectService(arg)), 
  }
}

const mapStateToProps = (store) => {
  return {
    user_input: store.page.user_input,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceView)