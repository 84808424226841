import PopUp from "../View/PopUp";

export function openPopUp(content) {
  return (dispatch) => {
    dispatch({
      type: "OPEN_POP_UP",
      payload: (
        <PopUp>
          {content}
        </PopUp>
      )
    })
  };
}

export function closePopUp() {
  return (dispatch) => {
    dispatch({
      type: "CLOSE_POP_UP",
      payload: null
    })
  };
}