import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { } from '../../actions/UserAct';
import { openPage,selectOrderType,selectService, selectMaster} from '../../actions/PageActions';
import PageView from '../PageView';
import CollapseListElement from '../components/CollapseListElement';
import Loading from '../components/Loading';
import { BtnBack } from '../components/BackBtn';

class SelectService extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ServicesPerCategories: []
    }

    this.getServices()
  }

  getServices(){
    const { user_input } = this.props
    global.API_task({
      operation: "getServices",
      master_id: user_input.master != null ? user_input.master.id : null,
    }).then( res => {
      let categories = []
      res.forEach( comp => {
        if (comp.data != null) {
          comp.data.category.forEach( category => {
            let c_index = categories.findIndex( c => c.id == category.id )
            if (c_index != -1) {
              if (categories[c_index].companies == null)
                categories[c_index].companies = [comp]
              else
                categories[c_index].companies.push(comp)
            } else {
              categories.push({
                ...category,
                companies:[comp]
              })
            }
          })
        }
      } )

      let services = []
      res.forEach( comp => {
        if (comp.data != null) {
          comp.data.services.forEach( service => {
            let c_index = services.findIndex( c => c.id == service.id )
            if (c_index != -1){
              if (services[c_index].companies == null)
                services[c_index].companies = [comp]
              else
                services[c_index].companies.push(comp)
            } else {
              services.push({
                ...service,
                companies: [comp]
              })
            }
          })
        }
      })

      categories.forEach( category => {
        category.services = []
        let nexStepServices = []

        services.forEach( service => {
          if (service.category_id == category.id)
            category.services.push(service)
          else
            nexStepServices.push(service)
        })
        services = nexStepServices
      })

      this.setState({ ServicesPerCategories: categories })
    }).catch(er=>{
    })
  }

  render() {
    const { ServicesPerCategories } = this.state
    const { user_input } = this.props

    if (ServicesPerCategories.length == 0) {
      return <Loading/>
    }

    return(
      <PageView>
        <div className="row">
          <div className="col-12 ">
            <Scroll>
              { ServicesPerCategories.map( category => (
                <CollapseListElement
                  childHeight={80}
                  Title={ <p>{ category.title }</p> }
                  Collapsed={
                    category.services.map( service => (
                      <Service.View
                        onClick={ () => {
                          this.props.selectService(service)
                          this.props.openPage("SelectDate")
                        } }
                      >
                        <Service.Selector/>
                        <Service.Title>{ service.title }</Service.Title>
                        <Service.Comment>{ service.comment }</Service.Comment>
                        <Service.Coast>{ service.price_min } руб</Service.Coast>
                      </Service.View>
                    ) )
                  }
                />
              ) ) }
            </Scroll>
          </div>
        </div>
      </PageView>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openPage: (arg) => dispatch(openPage(arg)),
    selectOrderType: (arg) => dispatch(selectOrderType(arg)),
    selectMaster: (arg) => dispatch(selectMaster(arg)),
    selectService: (arg) => dispatch(selectService(arg)),
  }
}

const mapStateToProps = (store) => {
  return {
    user_input: store.page.user_input,
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(SelectService)

const Service ={
  View:styled.div`
  height:80px;
  position:relative;
  cursor:pointer;
  &:hover div{
    transform:scale(1);
  }
  `,
  Selector:styled.div`
  height: 10px;
  width: 10px;
  border-radius: 200px;
  background: #db9a63;
  position: absolute;
  margin-left: -20px;
  top: 5px;
  transition:.3s ease-in-out;
  transform:scale(0);
  `,
  Title:styled.p`
  font-size: 13px;
font-weight: bold;
margin: 0px;
  `,
  Comment:styled.p`
  margin: 0px;
font-size: 11px;
line-height: 10px;
color: rgb(160,160,160);
font-weight: 300;
  `,
  Coast:styled.p`
  font-size: 14px;
  color:#db9a63;
  `,
}

const Title = styled.h3`
text-align:center;
background:white;
border-radius:20px;
box-shadow:10px 10px 30px rgba(0,0,0,0.1);
padding:20px;
z-index:1;
position:relative;
`

const Btn = styled.div`
width:200px;
`

const Scroll = styled.div`
height:calc(100vh - 140px);
border-radius:10px;
background:rgb(250,250,250);

overflow:scroll;
position:relative;
margin-top:0px;

z-index:0;
`
const ListItem = {
  Body:styled.div`
  height:50px;
  margin-bottom:20px;
  cursor:pointer;
  &:hover img{
    transform:scale(1.2);
  }
  `,
  Img:styled.img`
    border-radius:100px;
    width:50px;
    height:50px;
    display:block;
    position:absolute;
    transition:.3s ease-in-out;
  `,
  Fio:styled.div`
  margin-left: 60px;
  font-weight: bold;
  font-size: 12px;
  `,
  Specialization:styled.div`
  margin-left: 60px;
  font-size: 12px;
  margin-top: -4px;
  color: rgb(100,100,100);
  font-weight: 300;
  `,
  Rating:styled.div`
  margin-left: 60px;
  font-size: 11px;
  margin-top: -4px;
  color: #bd7d48;
  `,
  View:(props)=>{
    const {name,specialization,rating,avatar} = props.data

    return(
      <ListItem.Body onClick={()=>props.onClick()}>
        <ListItem.Img src={avatar}/>
        <ListItem.Fio>{name}</ListItem.Fio>
        <ListItem.Specialization>{specialization}</ListItem.Specialization>
        <ListItem.Rating>{rating}</ListItem.Rating>
      </ListItem.Body>
      )
  }
}
