export class EventsHolder{
  _ClientID

  constructor(ClientID) {
    if (ClientID == null) {
      console.error('NULL CLIENT ID')
      console.error('EventsHolder not created!')
      return
    }
    console.log('EventsHolder created for ' + ClientID)

    let href = window.location.href
    this._ClientID = ClientID + ''

    this.ev('OPEN_PAGE', { data: href })

    setInterval(() => {
      this.ev('I_AM_HERE')
    }, 5000)
  }

  ev(event_name, args) {
    if (this._ClientID == null) {
      console.error('NULL CLIENT ID')
      return
    }

    if (event_name == null) {
      console.error('Первый параметр event_name - Обязательный')
      return
    }

    //fetch(
    //  'https://quicksearch.richnails.club/server/events/index.php',
    //  {
    //    method: 'POST',
    //    headers: {
    //      'Content-Type': 'application/json;'
    //    },
    //    body: JSON.stringify({
    //      operation: 'event',
    //      ClientID: this._ClientID,
    //      EVENT: event_name,
    //      ARGS: args==null?null:JSON.stringify(args)
    //    })
    //  }
    //).then(() => {
    //  console.log(event_name + ' ok')
    //}).catch(er => {
    //  console.error(er)
    //})
  }
}