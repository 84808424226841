import React, { Component } from 'react';

import styled from 'styled-components';
import { connect } from 'react-redux';
import { getSelfInfo, setUserInfo } from '../actions/UserAct';
import { setCompanies } from '../actions/PageActions';
import {  } from '../actions/PageActions';

import ym from 'react-yandex-metrika'
import { YMInitializer } from 'react-yandex-metrika';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import { withCookies } from 'react-cookie';
import { EventsHolder } from '../EventsHolder'
import SuccessPage from './pages/SuccessPage';

class App extends Component {
  constructor(props) {
    super();
    this.state={
      display:0,
    }

    const { cookies } = props;
    global.startShedul = () => {
      //тут описываются все события, которые должны быть запущены после успешной авторизации
    }
  }

  componentDidUpdate(nextProps){
  }

  componentDidMount() {
    let urlParams = global.urlParams()
    global.ClientID_main = urlParams.ClientID

    const { cookies } = this.props.cookies;
    global.ClientID_widget_new = cookies._ym_uid

    let ClientID = null
    if (global.ClientID_main != null)
      ClientID = global.ClientID_main
    else
      ClientID = global.ClientID_widget_new

    let eventsHolder = new EventsHolder(ClientID)

    global.ev = (event_name,args) => {
      eventsHolder.ev(event_name,args)
    }

    global.ev('OPEN_WIDGET_NEW')

    let userData = {
      phone:    cookies.phone == null ? null : cookies.phone,
      email:    cookies.email == null ? null : cookies.email,
      fullname: cookies.fullname == null ? null : cookies.fullname,
    }
    this.props.setUserInfo(userData)

    ym('hit', '/mainPage')
    ym('reachGoal', 'page_loaded')
    this.getCompanies();
  }

  getCompanies() {
    const { companies } = this.props;

    global.API_task({
      operation: 'getCompanies'
    }).then( res => {
      this.props.setCompanies(res);
    }).catch( err => {
      alert('Ошибка получения списка филиалов. Попробуйте позже.');
    });
  }

  componentWillReceiveProps(nextProps){
    const oldPopUps = this.props.PopUps.length
    const newPopUps = nextProps.PopUps.length

    if(oldPopUps>0 && newPopUps==0){
      this.setState({ display: 1 })
      setTimeout(() => this.setState({ display: 0 }), 500)
    }else if(oldPopUps==0 && newPopUps>0){
      this.setState({ display: 1 })
      setTimeout(() => this.setState({ display: 2 }), 5)
    }
  }

  render() {
    const { page_data,PopUps } = this.props
    const { display } = this.state

    const view = (
      <div>
        <BlurBg display={display} />
        {PopUps}
        { !!global.originalWidgetUrl() ?
          <CurrentPage
            onClick={()=>{
              window.open(
                global.originalWidgetUrl(),
                '_blank'
              )
            } }
          >
            Стандартная запись в yclients
          </CurrentPage>
          : null
        }
        <Content>
          {page_data.current_page.view}
        </Content>
      </div>
    )
    return(
      <Router key="mainRouter" >
        <Switch>
            <Route path='/done'>
              <SuccessPage/>
            </Route>

            <Route path='/key/:URL_KEY/done'>
              <SuccessPage/>
            </Route>

            <Route path='/key/:URL_KEY'>
              {view}
            </Route>

            <Route path='/'>
              {view}
            </Route>
        </Switch>
      </Router>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
   // _getSelfInfo: (arg) => dispatch(getSelfInfo(arg)),
    setUserInfo: (arg) => dispatch(setUserInfo(arg)),
    setCompanies: (arg) => dispatch(setCompanies(arg))
  };
};

const mapStateToProps = (state) => {
  return {
    page_data: state.page.page_data,
    PopUps: state.popups.windows,
    companies: state.companies
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(App));

const BlurBg = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  display:${ props => props.display == 0 ? `none` : `block` };
  background: rgba(0,0,0,0.2);
  opacity:${ props => props.display == 2 ? 1 : 0 };
  transition:opacity .5s;
  width: 100vw;
  z-index:999;
`


const CurrentPage = styled.div`
  height:30px;
  background: rgb(247, 247, 247);
  width:100vw;
  position:absolute;
  top:0px;
  left:0px;
  text-align:center;
  line-height:29px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: rgb(60,60,60);
`
const Content = styled.div`
  height:calc(100vh - 30px);
  margin-top:30px;
  overflow:scroll;
`
