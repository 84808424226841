import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { } from '../../actions/UserAct';
import { openPage,selectOrderType,selectService, selectMaster} from '../../actions/PageActions';
import PageView from '../PageView';
import CollapseListElement from '../components/CollapseListElement';
import Loading from '../components/Loading';
import { BtnBack } from '../components/BackBtn';

class SelectMaster extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Masters: []
    }

    this.getMasters()
  }

  getMasters() {
    global.API_task({
      operation: "getMasters"
    }).then(res => {
      this.setState({ Masters: res })
    }).catch(er=>{
    })
  }

  render() {
    const { Masters } = this.state

    if (Masters.length == 0) {
      return <Loading/>
    }

    return(
      <PageView>
        <div className="row">
          { Masters.map( (comp,i) => (
            <div className="col-4">
              <Title>{ comp.title }</Title>
              <Scroll>
                { comp.data.map( master =>
                  <ListItem.View
                    data={ master }
                    onClick={ () => {
                      this.props.selectMaster(master)
                      this.props.openPage("SelectService")
                    } }
                  />
                ) }
              </Scroll>
            </div>
          ) ) }
        </div>

        <BtnBack
          className="btn btn-light btn-sm"
          onClick={ () => this.props.openPage("OrderType") }
        >Назад</BtnBack>
      </PageView>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openPage: (arg) => dispatch(openPage(arg)),
    selectOrderType: (arg) => dispatch(selectOrderType(arg)),
    selectMaster: (arg) => dispatch(selectMaster(arg)),
    selectService: (arg) => dispatch(selectService(arg)),
  }
}

const mapStateToProps = (store) => {
  return {
    //page_data: store.page.page_data,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectMaster)


const Title = styled.h3`
text-align:center;
background:white;
border-radius:20px;
box-shadow:10px 10px 30px rgba(0,0,0,0.1);
padding:20px;
z-index:1;
position:relative;
`



const Scroll = styled.div`
height:calc(100vh - 180px);
border-radius:10px;
background:rgb(250,250,250);
padding-left:20px;
padding-right:20px;
overflow:scroll;
position:relative;
margin-top:-30px;
padding-top:40px;
z-index:0;
`
const ListItem={
  Body:styled.div`
  height:50px;
  margin-bottom:20px;
  cursor:pointer;
  &:hover img{
    transform:scale(1.2);
  }
  `,
  Img:styled.img`
    border-radius:100px;
    width:50px;
    height:50px;
    display:block;
    position:absolute;
    transition:.3s ease-in-out;
  `,
  Fio:styled.div`
  margin-left: 60px;
  font-weight: bold;
  font-size: 12px;
  `,
  Specialization:styled.div`
  margin-left: 60px;
  font-size: 12px;
  margin-top: -4px;
  color: rgb(100,100,100);
  font-weight: 300;
  `,
  Rating:styled.div`
  margin-left: 60px;
  font-size: 11px;
  margin-top: -4px;
  color: #bd7d48;
  `,
  View: (props) => {
    const { name, specialization, rating, avatar } = props.data

    return (
      <ListItem.Body onClick={ () => props.onClick() }>
        <ListItem.Img src={ avatar }/>
        <ListItem.Fio>{ name }</ListItem.Fio>
        <ListItem.Specialization>{ specialization }</ListItem.Specialization>
        <ListItem.Rating>{ rating }</ListItem.Rating>
      </ListItem.Body>
    )
  }
}
