import React from 'react';
import './_checkBox.css';

export default class CheckBox extends React.Component {
  constructor(props) {
    super();
    this.state = {
      selected: ""
    }
    this.input = [];
    this.loaded = false;
  }

  componentDidMount(){
    if (this.props.isChecked === true){
      this.input.box.checked = true;
      this.setState({ selected: " selected" });
      this.loaded = true;
    } else {
      if (this.props.isChecked === null) {
        setTimeout(() => this.componentDidMount(), 100);
      } else {
        this.loaded = false;
      }
    }
  }

  chSelect() {
    this.input.box.checked = !this.input.box.checked;

    if (this.input.box.checked)
      this.setState({ selected: " selected" });
    else
      this.setState({ selected: "" });

    this.props.onChange(this.input.box.checked)
  }

  render(){
    return (
      <div className={ "checkBox " + this.props.className + this.state.selected }>
        <input
          type="checkbox"
          id="cbx"
          ref={ ref => this.input.box = ref }
          onChange={ () => this.props.onChange(this.input.box.checked) }
          style={{ display: "none" }}
        />
        <label onClick={ () => this.chSelect() } className="check">
        <svg width="18px" height="18px" viewBox="0 0 18 18">
          <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
          <polyline points="1 9 7 14 15 4"></polyline>
        </svg>
        </label>
        <small onClick={ () => this.chSelect() }>{ this.props.label }</small>
      </div>
    );
  }
}
